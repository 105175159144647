import React from "react";
import { LogoSVG } from "./artwork.js";
import "./App.scss";
import { JumpTo, formatId } from "./utils";
import Instructions from "./instructions";
function App() {
  return (
    <div className="App">
      <header className="App-header">
        <LogoSVG />
        <p>Every ride is an adventure.</p>
        <div className="links">
          <a
            className="cta"
            href="https://www.thegamecrafter.com/games/bussin-around2"
          >
            BUY IT!
          </a>
          <a className="cta" href="#instructions">
            LEARN TO PLAY!
          </a>
        </div>
        <div className="links social">
          <a className="cta" href="https://reddit.com/r/bussinaround">
            Reddit
          </a>
          <a className="cta" href="https://instagram.com/practicalgameco">
            Instagram
          </a>
          <a className="cta" href="https://twitter.com/practicalgameco">
            Twitter
          </a>
          <a className="cta" href="tel:‪414-885-5105‬">
            Text Hotline
          </a>
        </div>
      </header>
      <div className="after-header">
        <Instructions />
      </div>
    </div>
  );
}

export default App;
