import React from "react";
import { BusDeckIconSVG, RouteDeckIconSVG } from "./artwork";
import passengers_png from "./img/passengers.png";
import mod_goop_png from "./img/mod_goop.png";
import mod_transfer_png from "./img/mod_transfer.png";
import mod_lostitem_png from "./img/mod_lostitem.png";
import stops_png from "./img/stops.png";
import babies_png from "./img/babies.png";
import fugitives_png from "./img/fugitives.png";
import pigeons_png from "./img/pigeons.png";
import buslayout from "./img/buslayout.png";
import passenger_types from "./img/passenger_types.png";
import backs from "./img/backs.png";
import { JumpTo, formatId } from "./utils";

function Overview(props) {
  return (
    <React.Fragment>
      <h1 id="instructions">Welcome New Drivers</h1>
      <p>
        You are now{" "}
        <span className="rule">
          Official Bus Drivers for Dumpston Transit Authority [DTA]
        </span>
        .
      </p>
      <p>
        Your assignment is simple. Collect <JumpTo text="fares" /> by picking up{" "}
        <JumpTo text="passengers" /> and dropping them off at their respective{" "}
        <JumpTo text="stops" />.
      </p>
      <p>
        <span className="rule">
          Passengers have one or more <JumpTo text="traits" />
        </span>{" "}
        like <JumpTo text="sexy" id="traits" />,{" "}
        <JumpTo text="old" id="traits" />, and <JumpTo text="chad" id="traits" />
        . Traits let you know which passengers will get off and on
        your bus at each stop.{" "}
        
      </p>
      <p>
        Each of you is responsible for a bus that
        <span className="rule"> holds up to 5 passengers at a time</span>.
        And <span className="rule">each bus will make at most 5 stops</span>.
        Study your passengers and stops closely to make the most out of your route.
      </p>
      <p>
        On your turn, <span className="rule">you get to take one action.</span>{" "}
        You might use it to add a passenger to your bus, drop off passengers at
        a stop, or mess with your fellow drivers in any number of ways.
      </p>
      <p>
        If you only remember one thing make it this:{" "}
        <span className="rule">
          the driver who collects the most fares is named the{" "}
          <JumpTo text="Bus Champion" />.
        </span>
        {" "}The highest honor known to bus operators.
      </p>
      <p>Think this all sounds like a joy ride? Think again!</p>
      <p>
        <span className="rule">
          {" "}
          Not all passengers pay a <JumpTo text="Fare" id="fares" />
        </span>
        . In fact, some have been known to fill up entire buses, take hostages, and
        even explode a bus or two. If you see any <JumpTo text="demerit" />{" "}
        cards it’s best to make them somebody else’s problem. And if you do end
        up with one on your bus, get rid of it ASAP.{" "}
        <span className="rule">
          At <JumpTo text="the end of the line" /> drivers must discard one{" "}
          <JumpTo text="collected fare" id="fares" /> for each{" "}
          <JumpTo text="demerit" /> card
        </span>{" "}
        stuck on their bus.
      </p>
      <p>
        There are definitely a few things I’m forgetting to tell you, but if you use your
        brain and you'll probably figure it out.
      </p>
      <p>Godspeed.</p>
      <p>- Sparky, DTA Director of Safety</p>
    </React.Fragment>
  );
}

function Playthrough(props){
  return (
    <>
      <h1>Playthrough</h1>
      <p>To get a feel for the game, we recommend watching the 2021 Bussin' Around Bus Champion Championship.</p>
      <div class="videoWrapper">
        <iframe allowfullscreen="allowfullscreen"
        mozallowfullscreen="mozallowfullscreen" 
        msallowfullscreen="msallowfullscreen" 
        oallowfullscreen="oallowfullscreen" 
        webkitallowfullscreen="webkitallowfullscreen"
        frameBorder="0" width="560" height="315"  src="https://www.youtube.com/embed/5u-iKG380oI" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
      </div>
    </>
  )
}

function Card(props) {
  const { title, description, descriptionEl, rules, example } = props.data;
  const id = formatId(title);
  return (
    <div>
      <h2 id={id} className="card-title">
        {title}
      </h2>
      {description && <p>{description}</p>}
      {descriptionEl && descriptionEl}
      {rules && (
        <ol>
          {rules.map((rule) => (
            <li>{rule}</li>
          ))}
        </ol>
      )}
      {example && <p className="for-example">{example}</p>}
    </div>
  );
}

function QuickPlay(props) {
  return (
    <React.Fragment>
      <h1 id={formatId("instructions")}>Quick Play Rules</h1>
      <h2 id="objective">Objective</h2>
      <p>
        Collect the most fares by getting passengers on and off your bus to win
        the title of <JumpTo text="Bus Champion" />.
      </p>
      <h2 id="setup">Set Up</h2>
      <ol>
        <li>
          Sort the cards into their separate decks.
            <div style={{"margin-top":20}}>
            <img style={{"max-width":250}} src={backs} />
            </div>
        </li>
        <li id={formatId("hand")}>
          Deal each player 5 facedown cards from the <BusDeckIconSVG />{" "}
          <JumpTo text="bus deck" /> and 3 facedown cards from the{" "}
          <RouteDeckIconSVG /> <JumpTo text="route deck" />. This is the
          driver's <JumpTo text="hand" />. Drivers may now look at their own
          hand.
        </li>
        <li id={formatId("draw piles")}>
          Place the remaining cards from each deck in separate facedown piles in
          the middle of the play area. These are the{" "}
          <JumpTo text="draw piles" />.
        </li>
        <li id={formatId("bus")}>
          Leave space in front of each driver for their passengers, stops and
          collected fares. This is considered the driver's <JumpTo text="bus" />
          .
        </li>
        <li>
          The player who took <JumpTo text="public transportation" /> most
          recently goes first. For any additional rounds, the previous game’s
          Bus Champion goes first.
        </li>
      </ol>
      <img src={buslayout} />
      
      <h2 id="your-turn">On Your Turn</h2>
      <p id="actions">
        On your turn you get to take 1 <JumpTo text="action" id="actions"/>.
        {" "}Most of the time your action will be to play a card from your hand, on to a bus.
        {" "}Some cards give you more actions.
        {" "}If you don't want to play a card, you can use your action to <JumpTo text="swap out cards"/>.
      </p>
      <p>At the end of your turn, draw from either pile until you have 8 cards in your hand again.</p>
      <p>Speaking of cards, there are 3 main types of cards.</p>

      <h2 id={formatId("passengers")}>Passenger Cards</h2>
      <img stlye={{"margin-top":20}}src={passengers_png}/>
      <p>
        Play passenger cards from you hand, on any bus with an open seat.
        {" "}Each bus starts with 5 open seats.
        {" "}Once all seats are occupied, no more passengers can get on that bus.
      </p>
      
      <p>
        Each passenger has 1 or more traits like OLD, OLD HIGH, SEXY HIGH, and CHAD.
        {" "}These traits will come in handy later.
      </p>
      <img src={passenger_types}/>
      <p>
        Passengers count as either a <JumpTo text="fare"/> or a <JumpTo text="demerit"/>.
        {" "}Drivers collect fares when they get off their bus.
        {" "}Drivers must discard a collected fare for each demerit stuck on their bus at <JumpTo text="the end of the line"/>.
        {" "}As a general rule of thumb, put fares on your bus and demerits on other drivers' buses.
      </p>
      <p>
        Some passengers have special instructions on them.
      </p>
      
      <h3>Babies</h3>
      <img src={babies_png}/>
      <p>
        Babies have either a BOMB or a LIGHTER.
        {" "}When a bus contains both a bomb and a lighter, it explodes and all bus cards are promptly discarded.
      </p>

      <h3>Fugitives</h3>
      <img src={fugitives_png}/>
      <p>
        As they are on the lamb, Fugitives find refuge in our bus system by taking passengers hostage and stealing their identities.
        {" "}A fugitive can transfer any seated passenger sharing a trait into another bus, and take its original seat.
      </p>

      <h3>Pigeons</h3>
      <img src={pigeons_png}/>
      <p>
        Pigeons follow other pigeons. 
        {" "}When any player adds a pigeon onto a bus, each player (starting with the current player) may add as many pigeons as they want from their hand onto the same bus (until it is full).
        {" "}When a pigeon gets out of a bus, all other pigeons in that bus also get out.
        {" "}When a pigeon is transferred, all other pigeons from that bus can be transferred to the new bus until it is full, the rest are discarded.
      </p>
     
      <h2 id={formatId("stops")}>Stop Cards</h2>
      <img src={stops_png}/>
      <p>
        Play stop cards from your hand on any bus.
        {" "}Each stop has a unique set of instructions, follow them in order they are written.
        {" "}The instructions only apply the bus it was played on.
        {" "}After you've followed the instructions, keep it above the bus to track how many stops have been played on it.
      </p>
      
      <p>
          When a stop says passengers <JumpTo text="Get Off" />, remove those
          passengers from the bus and:
          <ol>
            <li>
              The driver (not necessarily the card player) collects all <JumpTo id="fares" text="Fare" /> passengers
              facedown in a pile next to their bus.
            </li>
            <li>
              Move all <JumpTo text="Demerit" /> passengers in the discard pile.
            </li>
          </ol>
          When a stop says passengers may <code>Get On</code>, the player of the
          stop can add any matching passengers from their hand onto that bus.
      </p>

      <h2 id={formatId("modifiers")}>Modifier Cards</h2>
      <p>
        Modifier cards are played from your hand on any bus. Each has instructions on the card.
        {" "} Here are a few common ones.
      </p>
      <h3>Transfers</h3>
      <img src={mod_transfer_png}/>
      <p>
        A transfer lets you move a passenger from any bus to any other bus.
        {" "}You might move a Fare Passenger from someone else's bus into yours
        {" "}or a Demerit Passenger from your bus into someone else's.
        {" "}Discard the transfer when you are done.
      </p>
      <h3>Lost Items</h3>
      <img src={mod_lostitem_png}/>
      <p>
        Lost Items count as fare.
        {" "}To play a lost item, on your turn, show it to the other drivers then add it to your Collected Fares pile.
        {" "}Watch out, though! There are a few cards that can take away your lost items.
      </p>
      <h3>Mystery Goop</h3>
      <img src={mod_goop_png}/>
      <p>
        Mystery Goops simply takes up a seat in a bus, and they are a demerit so they count against your collect fares at the end.
        {" "}They are not passengers, so they cannot be transferred.
        {" "}The best way to get rid of them is to blow up your own bus!
      </p>


      <h2 id="others-turn">Reaction Cards</h2>
      <p>
        There are a few <code>Reaction</code> cards that can be triggered by
        other player's actions. You may play these immediately. They do not
        count as your turn.
      </p>
      
      <h2 id={formatId("the end of the line")}>The End of the Line</h2>
      <p>
        Once any bus completes a 5th stop, your game has reached the End of the Line.
        All driver's follow these steps in order:
      </p>
      <ol>
        <li>
          Collect any <JumpTo text="fares" /> left in your bus.
        </li>
        <li id="demerit">
          Discard 1 collected fare for each <JumpTo text="demerit" /> card left
          in your bus.
        </li>
        <li>Discard your hand and any other cards left on your bus.</li>
      </ol>
      <p id="fares">
        Finally, all drivers pick up their pile of{" "}
        <JumpTo text="collected Fares" /> and count them out loud, in unison,
        while staring each other in the eyes. The person with the most collected
        fares is named the Bus Champion and every one else must clap for them.
      </p>
      <p>
        A tie may be settled with a <JumpTo text="bus off" />.
      </p>
      
    </React.Fragment>
  );
}

function GameDetails(props) {
  return (
    <React.Fragment>
      <p>
        <strong>Players: 2-4</strong><br />
        <strong>Time: 15-30 minutes</strong>  <br />
        <strong>Ages: 13+</strong> 
      </p>
    </React.Fragment>
  );
}

function OfficialRules(props){
  return (
    <>
      <h1>Nit-Picky Rules</h1>
      <p>In an argument? These rules should help settle things.</p>
      <h2 id={formatId("passengers")}>Playing a Passenger Card</h2>
      <ol>
        <li>
          Play a passenger card from your hand, on any bus with an open{" "}
          <JumpTo text="seat" id="seats" />.
        </li>
        <li id={formatId("seats")}>Each bus starts with 5 open seats.</li>
        <li>
          If all the seats on a bus are full, no more passengers can get on that
          bus.
        </li>
        <li>If all buses are full, no passengers can be played.</li>
      </ol>

      <h2 id={formatId("stops")}>Play a Stop Card</h2>
      <ol>
        <li>Play a stop from your hand on any driver's bus.</li>
        <li>The stop only affects the bus it was played on.</li>
        <li>
          Follow the instructions on the stop card in order they are written.
        </li>
        <li id={formatId("get-off")}>
          When a stop says passengers <JumpTo text="Get Off" />, remove those
          passengers from the bus and:
          <ol>
            <li>
              The driver (not necessarily the card player) collects all <JumpTo id="fares" text="Fare" /> passengers
              facedown in a pile next to their bus.
            </li>
            <li>
              Move all <JumpTo text="Demerit" /> passengers in the discard pile.
            </li>
          </ol>
        </li>
        <li id={formatId("get-on")}>
          When a stop says passengers may <code>Get On</code>, the player of the
          stop can add any matching passengers from their hand onto that bus.
        </li>
        <li>
          Any choices in the stop's instructions are made by the player of the
          card, unless the instructions say otherwise.
        </li>
        <li>
          After completing a stop, leave the card above the driver's bus to keep track
          of how many stops have been played on that bus.
        </li>
        <li>
          Once any bus completes a 5th stop, the game reaches{" "}
          <JumpTo text="the end of the line" />.
        </li>
        <li>
          Once your bus completes a 4th stop, it is customary to announce
          “Ring-a-ding-ding! Next stop, End of the Line!”
        </li>
        <li>
          Only 1 stop can be played per bus per turn, even if the player has
          extra <JumpTo text="actions" />.
        </li>
        <li>
          A stop only affects the bus it was played on the one time it was
          played.
        </li>
      </ol>

      <h2 id="swap-action">Swap out cards</h2>
      <p>
        You may return any number of cards from your hand to the bottom of their
        respective decks.
      </p>

      <h2 id="draw">Drawing Cards</h2>
      <p>Draw back up to 8 cards in hand.</p>
      <ol>
        <li>You may draw from either deck.</li>
        <li>
          If you need to draw more than 1 card, you may draw from both decks.
        </li>
        <li>
          You may look at each card you draw before deciding which deck to draw
          from next.
        </li>
        <li>
          Once you draw from a second deck, you cannot draw from the first deck
          again.
        </li>
      </ol>

      <h2 id="bus-off">Bus Off</h2>
      <p>
        In the event of a tie, all tied players may participate in one of the
        following bus offs.
      </p>
      <h3 id="fill-a-buser">The Fill-A-Buser</h3>
      <p>
        Play the game as normal EXCEPT the end of the line is also triggered as
        soon as any bus is full.
      </p>
      <h3 id="speeeeed">Speeeeed!!!!!</h3>
      <p>
        Play the game as normal EXCEPT there are no turns and the first player
        to collect 5 <JumpTo text="fares" /> wins. If any player reaches a 5th
        stop before collecting a 5th fare, the game ends as usual.
      </p>

    </>
  );
}

function CardDescriptions(props) {
  const baby = {
    title: "Babies",
    descriptionEl: (
      <p>
        Small but deadly. Babies are packing either a bomb or a lighter. If a
        bus contains both a bomb and a lighter it explodes. When a bus explodes,
        all{" "}<JumpTo text="passengers" /> and <JumpTo text="ongoing modifiers" />{" "}
        inside the bus are discarded.
      </p>
    ),
  };
  const pigeon = {
    title: "Pigeons",
    description: `
      Pigeons fly together. When any player adds a pigeon onto a bus, each player (starting with
      the current player) may add as many pigeons as they want from
      their hand onto the same bus until it is full.
        When a pigeon gets out of a bus, all other pigeons in that bus also get out.
        When a pigeon is transferred, all other pigeons from that bus can be
      transferred to the new bus until it is full, and then players can add from their hands.
      Generally, if it happens to one pigeon on a bus, it happens to all
      pigeons on that bus.
    `,
  };

  const fugitive = {
    title: "Fugitives",
    descriptionEl: (
      <p>
        Fugitives have the ability to transfer a passenger out of a bus and take
        their seat, as long as they share the same trait. The single trait
        Fugitive can transfer any passenger. The bus does not need to have an
        open seat to play a Fugitive, because it first transfers the passenger out of
        a bus. A Fugitive can
        be placed in any open seat without transferring a passenger.
      </p>
    ),
    example: `For example: HIGH OLD is on another bus, I can play the OLD FUGITIVE
    to transfer the HIGH OLD to my bus and leave the OLD FUGITIVE in its
    place.`,
  };

  const transfer = {
    title: "Transfer",
    description:
      "Transfer a passenger card on any bus to another seat on any bus.",
    rules: [
      "You may transfer a passenger from your bus to another bus.",
      "You may transfer a passenger from another bus into your bus.",
      "You may transfer a passenger between two other player's buses.",
    ],
  };

  const goop = {
    title: "Mystery Goop",
    description:
      "Takes up one of the seats on a bus and they are hard to get rid of.",
    rules: [
      `These are not passengers so they will not be removed by cards like
      transfers.`,
      "When a bus explodes, discard all Mystery Goops.",
    ],
  };
  const lostItem = {
    title: "Lost Item",
    description: `Free stuff! Show the other players this card and add it to your
    collected fares. Does not require an open seat.`,
  };

  const bear = {
    title: "Hungry Bear",
    description: `
      He's hungry, feed him. At the end of every turn of yours, if he is still on your bus,
       discard one passenger of your choice. Do not collect fares, they have been eaten. As soon as the bus is empty of other passengers, discard this card.
       This may be in the middle of the GET OFF and GET ON stages of a stop.`,
    example: `For example, you have HIGH CHAD and HUNGRY BEAR on your bus, you play the CLOWN CHAD stop. HIGH CHAD gets out. The bus is empty of other passengers, so HUNGRY BEAR gets out. You can then safely add SEXY CLOWN to your bus.`,
  };

  const tammy = {
    title: "Talkative Tammy",
    description: `
      Good golly, she is annoying... At the start of every turn of yours, if she is still on your bus,
       one passenger of your choice gets off. Collect if the passenger is a paying FARE.
       As soon as the bus is empty of other passengers, Tammy gets off.
       This may be in the middle of the GET OFF and GET ON stages of a stop.`,
    example: `For example, you have HIGH CHAD and TALKATIVE TAMMY on your bus, you play the CLOWN CHAD stop. HIGH CHAD gets out. The bus is empty of other passengers, so TALKATIVE TAMMY gets out. You can then add SEXY CLOWN to your bus.`,
  };

  const extendobus = {
    title: "Extend-O-Bus",
    descriptionEl: (
      <p>
        Boy-howdy! This card adds one extra open seat to the bus it is played
        on. The player also gets an extra <JumpTo text="action" id="actions" />{" "}
        this turn. The seat cannot be transferred because it is not a passenger.
        If this bus explodes, discard this card.
      </p>
    ),
  };

  const arrest = {
    title: "Citizen's Arrest",
    descriptionEl: (
      <p>
        When another driver adds any <JumpTo text="fugitive" id="fugitives" />{" "}
        on your bus, show this card, add it to your{" "}
        <JumpTo text="collected fares" />, return the transferred passenger and
        discard the <JumpTo text="fugitive" id="fugitives" />.
      </p>
    ),
  };

  const lookin = {
    title: "I was lookin' for that",
    descriptionEl: (
      <p>
        When another drivers plays a <JumpTo text="Lost Item" />, show this
        card, discard this card, and add the <JumpTo text="Lost Item" /> to your{" "}
        <JumpTo text="collected fares" />.
      </p>
    ),
  };

  const jon = {
    title: "Jon",
    description: "Like I said, nothin' special about jon.",
  };

  const fart = {
    title: "A Fart",
    description: `The card player chooses one passenger and drops off all others.
       `,
    example: `For example, a bus contains OLD, HIGH, and SEXY. The player decides SEXY farted. Drop off OLD and HIGH.
    If the remaining passenger is HIGH PIGEON, and SEXY PIGEON was dropped off, HIGH PIGEON will follow the SEXY PIGEON, leaving the bus empty.`,
  };

  const factory = {
    title: "Abandoned Factory",
    descriptionEl: (
      <p>
        Discard all <JumpTo text="demerit" /> passengers from this bus. Then the
        card player has a choice to add <JumpTo text="demerit" /> passengers on
        to this bus.
      </p>
    ),
    example: `For example, this stop is played on another driver's bus
    containing PIGEON and PUNK. PIGEON is discarded, then the player decides
    to add FUGITIVE, OLD FUGITIVE and BABY to the bus.`,
  };

  const badPartOfTown = {
    title: "Bad Part of Town",
    descriptionEl: (
      <p>
        The driver of this bus must discard all{" "}
        <JumpTo text="Lost Items" id="lost item" /> from their{" "}
        <JumpTo text="collected fares" />. The player of the card may add any{" "}
        <JumpTo text="Demerit Modifiers" /> onto this bus.
      </p>
    ),
    example: `For example, the player plays this stop on a driver who has already collected two lost items.
     the player adds two Mystery Goops to the bus, then the driver must discard the two lost items from their collected fares.`,
  };

  const cutters = {
    title: "Calorie Cutters",
    description: `Discard half the number of passengers on their bus, rounding up.
      The driver of the bus, not the player of the card, decides which passengers to discard.
      Do not collect any fares.`,
    example: `For example, a bus contains HIGH, OLD HIGH, SEXY, OLD SEXY, and PIGEON. The driver of the bus decides to discard SEXY, HIGH, and PIGEON.`,
  };

  const convention = {
    title: "Convention Center",
    description: `
    The player names one trait.
      All passengers with that trait get off.
      Then the player may add any passengers with that trait from their hand on to that bus.
      
    `,
    example: `For example, a bus contains CHAD, SEXY CHAD, and OLD. The player decides its a CHAD convention and drops off CHAD and SEXY CHAD, but not OLD. Then adds HIGH CHAD and OLD CHAD on to the bus.`,
  };

  const deepClean = {
    title: "Deep Clean",
    descriptionEl: (
      <p>
        Discard all <JumpTo text="demerit modifiers" /> from this bus, then the
        driver of the bus can collect any LOST ITEMS.
      </p>
    ),
    example: `For example, a bus
    contains 2 MYSTERY GOOPS and the driver has 2 LOST ITEMS in their hand;
    the driver discards both MYSTERY GOOPS and collects both LOST ITEMS.`,
  };

  const expressLane = {
    title: "Express Lane",
    description: `
      The driver of the bus this stop was played on (not the player of the card) is allowed to take 3 more actions on this turn.
    `,
    example: `For example, a player plays this stop on their own bus, collects 1 LOST ITEM, adds SEXY to their own bus and add HIGH FUGITIVE to another driver’s bus.`,
  };

  const grandest = {
    title: "Grandest Most-Central Station",
    description: `
      The player may add as many passengers as they want from their hand to this bus.
      
    `,
    example: `For example, a player plays this on another driver's bus and adds PIGEON, FUGITIVE and BABY.`,
  };

  const intersect = {
    title: "Intersect",
    description: `
      When you play this stop, name another stop that has already been played on any bus and follow its instructions for this bus.
        
    `,
    example: `For example, if Abandoned Factory was already played on another stop, the player play this stop on their own bus to also remove DEMERIT passengers.
    If there are no stops in play, this stop does nothing.`,
  };

  const junkyard = {
    title: "Junk Yard",
    description: `
      The driver of the bus this is played on, not necessarily the player of the card, may look through the discard pile and choose 1 card to put into their hand.
        Then the player gets to take 1 extra action.
       
    `,
    example: `For example, the player plays the stop on their own bus, selects a TRANSFER from in the discard pile, adds it to their hand, then plays it to transfer an OLD into their bus. 
    If the discard pile is empty, the driver does not get a card but still gets the extra action.`,
  };

  const antonios = {
    title: "Little Antonios’",
    description: `
      Romance is in the air! Passengers that share a trait get out in pairs of 2. If there are multiple combinations of shared traits, the player of the card chooses.
      The player of the card can add passengers to this bus that share a trait, in pairs of 2. FIY, The trailing apostrophe is NOT a mistake. This place is owned by two guys name Antonio.
    `,
    example: `For example, a bus contains HIGH OLD, OLD BABY, and HIGH. The player drops off HIGH OLD and OLD, then adds CLOWN and SEXY CLOWN.`,
  };

  const cultural = {
    title: "Multi-Cultural Center",
    description: `
      Lets celebrate diversity!
      All passengers that DO NOT share a trait with any other passenger get off.
      The player may add passengers one by one that do not share a trait with any other passengers on that bus.
      If the bus is empty of passengers, any passenger can be added to the bus.
    `,
    example: `For example, a bus contains HIGH, OLD HIGH, CLOWN, and SEXY, the player drops off CLOWN and SEXY but not HIGH and OLD HIGH because they are both HIGH.
    Then the player may add SEXY CHAD, but not SEXY OLD because there is already another OLD on the bus.`,
  };

  const turf = {
    title: "Turf War",
    description: `
     First the player names a trait, then all passengers on this bus with that trait get out.
     Then the player names a different trait, and may add passenger to this bus that have this trait.
    `,
    example: `For example, a bus contains FUGITIVE, OLD FUGITIVE, and OLD. The player names FUGITIVE and drops off FUGITIVE and OLD FUGITIVE.
    Then the player names INJURED and picks up INJURED OLD and INJURED CHAD.`,
  };

  const bunnies = {
    title: "Two Bunnies Kissin’",
    descriptionEl: (
      <p>
        Its so cute, everyone gets out to see! All passengers get off of this
        bus. Do not discard any <JumpTo text="modifiers" />.
      </p>
    ),
  };

  const publicTransport = {
    title: "Public Transportation",
    description: `
      To be considered public transportation, it must be transportation operated by a public entity like a city.
        Buses, subways, light-rails, public ride-share all count.
        Airplanes, taxis, scooter rentals operated by private companies do not count.
    `,
  };

  const must = [baby, pigeon, fugitive];

  const nice = [
    transfer,
    goop,
    lostItem,
    bear,
    tammy,
    extendobus,
    arrest,
    lookin,
    jon,
  ];

  const stops = [
    fart,
    factory,
    badPartOfTown,
    cutters,
    convention,
    deepClean,
    expressLane,
    grandest,
    intersect,
    junkyard,
    antonios,
    cultural,
    turf,
    bunnies,
  ];

  const definitions = [publicTransport];

  function sortByTitle(first, second) {
    if (first.title.toUpperCase() > second.title.toUpperCase()) {
      return 1;
    }
    return -1;
  }

  must.sort(sortByTitle);
  nice.sort(sortByTitle);
  stops.sort(sortByTitle);
  definitions.sort(sortByTitle);

  return (
    <React.Fragment>
      <h1 id="must-know">Must Know</h1>
      {must.map((card) => (
        <Card data={card} />
      ))}

      <h1 id="nice-to-know">Good to Know</h1>
      {nice.map((card) => (
        <Card data={card} />
      ))}

      <h1 id="special-stops">Special Stops</h1>
      {stops.map((card) => (
        <Card data={card} />
      ))}

      <h1 id="facts">DaTA Facts</h1>
      {definitions.map((card) => (
        <Card data={card} />
      ))}
    </React.Fragment>
  );
}

export default function Instructions(props) {
  return (
    <div className="readable">
      <GameDetails />
      <Overview />
      <Playthrough />
      <QuickPlay />
      <OfficialRules/>
      <CardDescriptions />
    </div>
  );
}
