import React from "react";

export function formatId(text) {
  return text.toLowerCase().replace(" ", "-");
}

export function JumpTo(props) {
  const { text, id } = props;
  const link = "#" + formatId(id || text);
  return (
    <a href={link} className="jump-to">
      {text}
    </a>
  );
}
